import React, { useMemo, useState, useEffect, useRef } from "react";
import { Auth, API } from "aws-amplify";
import { checkUserNeedsAction } from "graphql/queries";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import { Redirect, useLocation } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Cookies from "universal-cookie";
import PropTypes from "prop-types";

import { standardObject } from "./standardObject";
import PrimaryBtn from "components/buttons/Primary";
import AgreedToTerms from "components/FormInputs/AgreedToTerms";
import AgreedToMarketing from "components/FormInputs/AgreedToMarketing";
import Username from "components/FormInputs/Username";
import { UserExistsMarkup } from "../Components/UserExistsMarkup";
import Email from "components/FormInputs/Email";
import Location from "components/FormInputs/Location";
import Password from "components/FormInputs/Password";
import ConfirmPassword from "components/FormInputs/ConfirmPassword";
import Error from "components/FormComponentsNew/Error";
import { REFERRAL_SOURCES } from "utils/referralSources";
import ReferralCodeSource from "components/FormInputs/ReferralCodeSource";
import DefaultInput from "components/FormInputs/DefaultInput";
import ReferrerCode from "components/FormInputs/ReferrerCode";
import Phone from "components/FormInputs/Phone";
import { FIELDS } from "pages/Profile/Wizard/ProfessionalProfile/Profile/utils";
import { useHistory } from "react-router-dom";
import SvgIcon from "components/SvgIcon";
import { USER_NEEDS_ACTION_STATUS } from "lookup";

const EMAIL_TAKEN = "A user with that email address already exists.";
const USERNAME_CANNOT_BE_EMAIL =
  "Username cannot be of email format, since user pool is configured for email alias.";
const USERNAME_TAKEN = "User already exists";
const ALTERNATE_REGISTRATION =
  "Blocked. User must complete alternate registration route.";

const EmailForm = ({ userName, setUserName, isEnterprise }) => {
  const history = useHistory();
  const elementRef = useRef(null);
  const [step, setStep] = useState(1);
  const [isCheckingNeedsActionApi, setIsCheckingNeedsActionApi] =
    useState(false);
  const [completedForm, setCompletedForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [phone, setPhone] = useState({
    number: "",
    whatsAppAllowed: false,
  });
  const params = new URLSearchParams(useLocation().search);
  const cookies = useMemo(() => new Cookies(), []);

  useEffect(() => {
    elementRef.current.scrollIntoView({ behavior: "smooth" });

    if (step === 1) {
      trigger();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const detectedReferrerCode =
    cookies.get("referralCode") || params.get("referralCode") || "";
  const detectedReferralSource =
    cookies.get("refSource") || params.get("refSource") || "";

  let validationSchema;
  if (step === 1) {
    validationSchema = {
      email: Yup.string().email().required("Enter your email address"),
    };
  }

  if (step === 2) {
    //if the user is a customer add the customer validation object
    if (isEnterprise) {
      standardObject.company = Yup.string()
        .required("Please enter your company name")
        .min(2, "Company name must be at least 2 characters");
    } else {
      delete standardObject.company;
    }

    validationSchema = { ...standardObject };
  }

  const formOptions = {
    resolver: yupResolver(Yup.object().shape(validationSchema)),
    defaultValues: {
      firstname: params.get("firstname") || "",
      lastname: params.get("lastname") || "",
      email: params.get("email") || "",
      referrerCode: detectedReferrerCode || "",
      agreedToMarketing: true,
    },
    mode: "all",
  };
  const methods = useForm(formOptions);
  const {
    setError,
    handleSubmit,
    register,
    watch,
    setValue,
    clearErrors,
    trigger,
  } = methods;
  const { errors } = methods.formState;

  const watchedEmail = watch("email");

  useEffect(() => {
    if (errors.apiError) {
      clearErrors("apiError");
    }

    if (errors.email) {
      clearErrors("email");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedEmail]);

  if (completedForm)
    return <Redirect to={`/verification?username=${userName}`} />;

  const redirectToExternalClaimPage = (email) => {
    if (!email) {
      return;
    }

    const searchParams = new URLSearchParams({
      email: email.toLowerCase(),
    });

    history.push({
      pathname: "/login/external",
      search: `?${searchParams.toString()}`,
    });
  };

  async function onSubmit({
    username,
    password,
    firstname,
    lastname,
    referrerCode,
    referralSource,
    email,
    company,
    isEnterprise,
    agreedToTerms,
    agreedToMarketing,
    location,
  }) {
    username = username.trim();

    try {
      setSubmitting(true);
      const attributes = {
        given_name: firstname.trim(),
        family_name: lastname.trim(),
        "custom:referrer_code": referrerCode,
        "custom:referrer_source": referrerCode
          ? REFERRAL_SOURCES[detectedReferralSource || referralSource]
          : undefined,
        "custom:company": company?.trim(),
        "custom:user_type": isEnterprise,
        "custom:terms_agreed": agreedToTerms.toString(),
        "custom:marketing_agreed": agreedToMarketing.toString(),
        "custom:location": JSON.stringify(location),
        email: email.toLowerCase(),
      };

      if (phone?.number?.length > 7) {
        attributes["custom:phone"] = JSON.stringify(phone);
      }

      const user = await Auth.signUp({
        username,
        password,
        attributes,
        autoSignIn: {
          enabled: true,
        },
      });

      if (window.analytics)
        window.analytics.identify(user?.userSub, {
          given_name: firstname.trim(),
          family_name: lastname.trim(),
          email: email.toLowerCase(),
          username: username,
          signUpDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        });

      // Remove the referrer code since it is now used
      cookies.remove("referralCode");
      cookies.remove("refSource");

      setUserName(username);
      setCompletedForm(true);
    } catch (error) {
      if (error.message) {
        const preSignUpError = "PreSignUp failed with error ";
        if (new RegExp(preSignUpError).test(error.message)) {
          const formattedMsg = error.message.replace(preSignUpError, "");
          if (formattedMsg === EMAIL_TAKEN) {
            setError("email", { message: formattedMsg });
            setError("apiError", { message: formattedMsg });
          } else if (formattedMsg === ALTERNATE_REGISTRATION) {
            redirectToExternalClaimPage(email);
          } else {
            setError("apiError", { message: formattedMsg });
          }
        } else if (new RegExp(USERNAME_TAKEN).test(error.message)) {
          setError("username", { message: USERNAME_TAKEN });
          setError("apiError", { message: USERNAME_TAKEN });
        } else if (new RegExp(USERNAME_CANNOT_BE_EMAIL).test(error.message)) {
          const errorMessage =
            "Your username should not be an email address. Please choose a username that is creative and shows your personality";
          setError(
            "username",
            { message: errorMessage },
            { shouldFocus: true }
          );
        } else {
          setError("apiError", { message: error.message || error });
        }
      } else {
        setError("apiError", { message: error.message || error });
      }
      setCompletedForm(false);
      setSubmitting(false);
    }
  }

  const handleBlur = ({ target }) => {
    const key = target.id;
    const value = target.value !== "" ? target.value.trim() : target.value;

    if (key === "phone") {
      setPhone({ ...phone, number: value });
      return;
    }
    if (key === FIELDS.WHATSAPP_ALLOWED) {
      setPhone({ ...phone, [key]: target.checked });
      return;
    }
  };

  const checkSignUpNeedsAction = async (inputEmail) => {
    try {
      setIsCheckingNeedsActionApi(true);
      const { data } = await API.graphql({
        query: checkUserNeedsAction,
        variables: { email: inputEmail },
        authMode: "API_KEY",
      });

      setIsCheckingNeedsActionApi(false);

      const { status } = data.checkUserNeedsAction || {};

      // USER_NOT_FOUND proceed to sign-up form
      if (status === USER_NEEDS_ACTION_STATUS.USER_NOT_FOUND) {
        setStep(2);
        return;
      }

      // user found and needs action (claim account)
      if (status === USER_NEEDS_ACTION_STATUS.NEEDS_ACTION) {
        redirectToExternalClaimPage(inputEmail);
        return;
      }

      // user found and doesn't needs action show login message
      if (status === USER_NEEDS_ACTION_STATUS.NO_ACTION_NEEDED) {
        setError("email", { message: EMAIL_TAKEN });
        setError("apiError", { message: EMAIL_TAKEN });

        return;
      }
    } catch (err) {
      console.log(err);
      setError("email", { message: "Something went wrong please try again" });
    }
    setIsCheckingNeedsActionApi(false);
  };

  const handleContinueButton = async (e) => {
    e.preventDefault();
    if (step === 1) {
      if (watchedEmail?.trim()) {
        await checkSignUpNeedsAction(watchedEmail.trim());
      }

      return;
    }
  };

  return (
    <div className="form-small">
      <h2 ref={elementRef} className="mb-12">
        Sign up with Email
      </h2>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col sm:gap-12 gap-8"
          onBlur={handleBlur}
        >
          {step === 1 && (
            <div className="w-full flex flex-col items-center min-h-[300px]">
              <div className="relative w-full">
                <Email preventDefaultOnEnter />

                {isCheckingNeedsActionApi && (
                  <SvgIcon
                    type="spinnerCircle"
                    className="w-[20px] h-[20px] animate-spin absolute top-4 right-3"
                  />
                )}
              </div>
              <PrimaryBtn
                id="nextButton"
                label="Continue"
                className="mt-8 w-[300px]"
                disabled={
                  errors.email &&
                  errors.email.message !==
                    "Something went wrong please try again"
                }
                onClick={handleContinueButton}
              />
            </div>
          )}

          {step === 2 && (
            <>
              <DefaultInput id="firstname" label="First Name" />
              <DefaultInput id="lastname" label="Last Name" />
              <Email />

              {!isEnterprise && (
                <div>
                  <Phone phone={phone} handleChange={handleBlur} />
                </div>
              )}

              <Username />
              <Location
                location={watch("location")}
                setLocation={(loc) =>
                  setValue("location", loc, {
                    shouldValidate: true,
                  })
                }
              />
              {isEnterprise && (
                <DefaultInput id="company" label="Company name" />
              )}
              <Password />
              <ConfirmPassword />
              <ReferrerCode />
              {!detectedReferralSource && watch("referrerCode") && (
                <ReferralCodeSource />
              )}

              <div className="gap-3 flex flex-col">
                <AgreedToTerms isEnterprise={isEnterprise} />
                <AgreedToMarketing />
              </div>
              <input
                type="hidden"
                {...register("isEnterprise", { required: true })}
                id="isEnterprise"
                defaultValue={isEnterprise ? "CUSTOMER" : "FREELANCER"}
              />

              <PrimaryBtn
                id="signUpButton"
                loading={submitting}
                data-cy="signup"
                label={!submitting ? "Submit" : "Submitting..."}
                disabled={submitting}
                className="sm:ml-auto mt-8"
              />

              {errors.apiError && (
                <ErrorMessage
                  errors={errors}
                  name="apiError"
                  render={({ message }) => (
                    <Error
                      message={
                        message === EMAIL_TAKEN ||
                        message === USERNAME_TAKEN ? (
                          <UserExistsMarkup />
                        ) : (
                          message
                        )
                      }
                    />
                  )}
                />
              )}
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

EmailForm.propTypes = {
  userName: PropTypes.string,
  setUserName: PropTypes.func.isRequired,
  isEnterprise: PropTypes.bool.isRequired,
};

export default EmailForm;
