import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useLocation, Link, useParams, Redirect } from "react-router-dom";
import { API, graphqlOperation, Auth } from "aws-amplify";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

import UtilsLib from "utils/lib";

import { ReactComponent as ShareIcon } from "images/socialShare/Share_icon.svg";
import { ReactComponent as ShareIconHover } from "images/socialShare/Share_icon_hover.svg";
import { ReactComponent as ShareIconCopied } from "images/socialShare/Share_icon_copied.svg";
import { ReactComponent as Pencil } from "images/new/pencil.svg";
import { ReactComponent as Plus } from "images/new/plus.svg";
import { ReactComponent as FeedBackIcon } from "images/Feedback_icon.svg";
import { ReactComponent as FeedbackHoverIcon } from "images/Feedback_icon_after.svg";
import { ReactComponent as JobsBackground } from "images/Jobs_background.svg";
import { ReactComponent as Trash } from "images/new/trash.svg";
import classNames from "classnames";
import { sortBy } from "lodash";

import {
  JOB_APPLICATION_MATCH_STATUS,
  GLOBAL_AUTH_ACTION_TYPES,
  SOCIAL_LINK_TYPES,
  SOCIALLINKS,
  NOTE_TYPES,
  JOB_OPPORTUNITY_VISIBILITY_LEVELS,
  REFERRAL_TYPES,
  CURRENCY_CONFIG,
} from "lookup";

import { getJobDuration } from "helpers/utils";
import { createMatch, updateMatch } from "graphql/mutations";
import { updateUser } from "graphql/mutations";
import { useAuth, useAuthDispatch } from "GlobalAuthContext";

import Header from "components/Header";
import Footer from "components/Footer";
import Input from "components/FormComponentsNew/Input";
import Modal from "components/Modal";
import NotInterestedInJob from "./NotInterested";

import Skills from "pages/Profile/Wizard/StackAndSkills/Skills";
import Phone from "components/FormInputs/Phone";
import DefaultInput from "components/FormInputs/DefaultInput";
import { yupResolver } from "@hookform/resolvers/yup";
import Textarea from "components/FormComponentsNew/Textarea";
import Checkbox from "components/FormComponentsNew/Checkbox";
import PrimaryBtn from "components/buttons/Primary";
import { SKILL_EXPERIENCE } from "utils/skills";
import { SkillsModal } from "pages/Profile/Wizard/StackAndSkills/Skills/SkillsModal";
import Error from "components/FormComponentsNew/Error";
import ScheduleLinkButton from "components/Scheduler/ScheduleLinkButton";
import ScheduleEditButton from "components/Scheduler/ScheduleEditButton";
import {
  getFreelancerJobOpportunity,
  getFreelancerMatches,
} from "graphql/queries";
import NotesWrapper from "components/Notes";
import { InformationCard } from "./InformationCard";
import { getPurifiedParsedMarkDown } from "utils/markdown";
import { FIELDS } from "pages/Profile/Wizard/ProfessionalProfile/Profile/utils";
import { convertSalaryToRatePerHour } from "utils/general";
import Rate from "./molecules/Rate";
import { getInitialCurrencies, getINRDefaultCurrency } from "helpers/currency";

const ApplicationSkills = ({ skills, setSkillSelected, deleteSkill }) => {
  return (
    <div className="grid sm:grid-cols-8 grid-cols-4 gap-[52px] mb-6">
      {skills.map((skill, index) => (
        <div
          key={index}
          style={{
            boxShadow: "0 12px 32px 0 rgba(0, 0, 0, 0.1)",
          }}
          className={classNames(
            "relative b5 leading-3 border flex items-center justify-between p-4 text-center gap-2 flex-col rounded-sm border-brandSecondary w-[100px] h-[120px]",
            {
              "border-red-500": !skill.experience && skill.hasJobSkill,
            }
          )}
        >
          <div className="max-h-[60px] overflow-hidden">{skill.name}</div>
          {skill.experience || skill.hasJobSkill ? (
            <div>
              <div
                className={classNames(
                  "flex gap-[2px] items-center cursor-pointer bg-brandPrimary h-[22px] text-[10px] p-2 rounded-xs leading-[18px] tracking-[-0.4px] font-rubik-regular",
                  {
                    "border-red-500 border-2": !skill.experience,
                  }
                )}
                onClick={() => setSkillSelected(skill)}
              >
                {SKILL_EXPERIENCE[skill.experience]?.briefLabel || "TBD"}
                <Pencil
                  className="cursor-pointer"
                  onClick={() => setSkillSelected(skill)}
                />
              </div>
              {!!deleteSkill && (
                <Trash
                  className="absolute top-[-6px] right-[-4px] cursor-pointer"
                  onClick={() => deleteSkill(skill.name)}
                />
              )}
            </div>
          ) : (
            <div
              className="flex gap-[2px] items-center cursor-pointer bg-brandSecondary-300 h-[22px] text-[10px] p-2 rounded-xs leading-[18px] tracking-[-0.4px] font-rubik-regular"
              onClick={() => setSkillSelected(skill)}
            >
              <Plus />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const getRejectedMessage = (status) => {
  if (
    status === JOB_APPLICATION_MATCH_STATUS.PASSEDON ||
    status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER ||
    status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER
  ) {
    return `Thank you for your interest in the job. Unfortunately, the
    customer did not select you for this position.`;
  }

  if (status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER) {
    return `Thank you for your feedback. We will use this information
    to improve future matches.`;
  }
};

const MatchedJob = () => {
  const SCHEDULER_ENABLED = process.env.REACT_APP_NYLAS_INTEGRATION
    ? JSON.parse(process.env.REACT_APP_NYLAS_INTEGRATION).isEnabled
    : false;
  const NYLAS_GOOGLE_ONLY =
    process.env.REACT_APP_COGNITO_GROUP_NYLAS_GOOGLE_ONLY;

  const COGNITO_GROUP_PROFESSIONAL_COMMUNITY =
    process.env.REACT_APP_COGNITO_GROUP_PROFESSIONAL_COMMUNITY;

  const nylasVersion = process.env.REACT_APP_NYLAS_VERSION || "V2";

  const location = useLocation();
  const { id } = useParams();
  const auth = useAuth();
  const dispatch = useAuthDispatch();
  const applicationRef = useRef();
  const linkCalendarRef = useRef();
  const [userSkills, setUserSkills] = useState([]);
  const [skillSelected, setSkillSelected] = useState(false);
  const [jobOppSkills, setJobOppSkills] = useState([]);
  const [jobOppOptSkills, setJobOppOptSkills] = useState([]);

  const [jobOpp, setJobOpp] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [match, setMatch] = useState(null);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState({});
  const [isUpdatingRecord, setIsUpdatingRecord] = useState(false);
  const [notInterested, setNotInterested] = useState(false);
  const [shouldLinkCalendar, setShouldLinkCalendar] = useState(true);

  const [hasScrolled, setHasScrolled] = useState(false);
  const [cognitoGroups, setCognitoGroups] = useState([]);
  const [isReferral, setIsReferral] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const applicationId = useRef();

  const schemaShapeRef = useRef({});

  const locationINRConversionEnabled =
    auth.user.location?.countryName &&
    auth.user.location?.countryName === CURRENCY_CONFIG.locationCountryName;

  const conversionIsAllowed =
    (locationINRConversionEnabled && CURRENCY_CONFIG.exchangeFactor) ||
    (!CURRENCY_CONFIG.exchangeFactor && CURRENCY_CONFIG.url);

  const initialCurrencies =
    locationINRConversionEnabled && CURRENCY_CONFIG.exchangeFactor
      ? { ...getInitialCurrencies(CURRENCY_CONFIG.exchangeFactor) }
      : {};

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const handleScroll = (ref) => {
    window.scrollTo({
      left: 0,
      top: ref?.offsetTop,
      behavior: "smooth",
    });
  };

  const isUserNylasGoogleOnly = useCallback(() => {
    return cognitoGroups.includes(NYLAS_GOOGLE_ONLY);
  }, [cognitoGroups, NYLAS_GOOGLE_ONLY]);

  const isProfessionalCommunity = useMemo(() => {
    return cognitoGroups.includes(COGNITO_GROUP_PROFESSIONAL_COMMUNITY);
  }, [cognitoGroups, COGNITO_GROUP_PROFESSIONAL_COMMUNITY]);

  const methods = useForm({
    resolver: yupResolver(Yup.object().shape(schemaShapeRef.current)),
    defaultValues: {
      startDate: "",
      freelancerPitch: "",
      whatsAppAllowed: auth.user.phone?.whatsAppAllowed,
      ratePerHourInput: 0,
    },
    mode: "all",
  });

  const { setValue, getFieldState, getValues, formState, setFocus } = methods;

  const { errors: formErrors } = formState;

  useEffect(() => {
    (async () => {
      try {
        const session = await Auth.currentSession();

        const groups = session.getAccessToken().payload["cognito:groups"] || [];

        setCognitoGroups(groups);
      } catch (err) {
        console.log("Error getting current session", err);
      }
    })();

    if (jobOpp) {
      document.title = `Match for ${jobOpp.id} - ${auth.user.username} `;
      setUserSkills(
        auth.user.skills
          ?.filter((skill) =>
            [...(jobOpp.skills || []), ...(jobOpp.optionalSkills || [])]?.every(
              (jobSkill) => jobSkill.name !== skill.name
            )
          )
          .sort((a, b) =>
            a.experience && b.experience
              ? (b.experience || 0) - (a.experience || 0)
              : !b.experience - !a.experience
          ) || []
      );

      const mappedJobOptSkills =
        jobOpp.optionalSkills?.map((skill) => {
          const jobSkill = auth?.user?.skills?.find(
            (s) => s.name === skill.name
          );
          return {
            ...skill,
            hasJobSkill: !!jobSkill,
            experience: jobSkill?.experience,
          };
        }) || [];

      const sortedJobOptSkills = sortBy(mappedJobOptSkills, [
        ({ hasJobSkill, experience }) => {
          return hasJobSkill && !experience ? -1 : 1;
        },
        ({ experience }) => {
          return experience ? -1 : 1;
        },
      ]);
      setJobOppOptSkills(sortedJobOptSkills);

      const mappedJobSkills =
        jobOpp.skills?.map((skill) => {
          const jobSkill = auth?.user?.skills?.find(
            (s) => s.name === skill.name
          );
          return {
            ...skill,
            hasJobSkill: !!jobSkill,
            experience: jobSkill?.experience,
          };
        }) || [];

      const sortedJobSkills = sortBy(mappedJobSkills, [
        ({ hasJobSkill, experience }) => {
          return hasJobSkill && !experience ? -1 : 1;
        },
        ({ experience }) => {
          return experience ? -1 : 1;
        },
      ]);
      setJobOppSkills(sortedJobSkills);

      if (params.get("success") === "true" && !hasScrolled) {
        setHasScrolled(true);
        handleScroll(applicationRef.current);
      }

      if (params.get("error")) {
        handleScroll(linkCalendarRef.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOpp, auth]);

  const deleteSkill = (skillName) => {
    const index = auth?.user?.skills.findIndex((s) => s.name === skillName);

    const appsyncUserSkills = [...auth?.user?.skills];

    appsyncUserSkills.splice(index, 1);

    setUserSkills(appsyncUserSkills);
    saveAttributes({ skills: appsyncUserSkills }, true);
  };

  const getCombinedPrefillPitch = (str1, str2) => {
    return `${str1 || ""}${
      str2 && str1 ? `\n\n${str2 || ""}` : `${str2 || ""}`
    }`;
  };

  const getFreelancerJobOpp = async () => {
    const res = await API.graphql(
      graphqlOperation(getFreelancerJobOpportunity, { id })
    );
    return res.data.getJobOpportunity;
  };

  const getUserWithJobMatch = async (userId, jobOpportunityId) => {
    const { data } = await API.graphql({
      query: getFreelancerMatches,
      variables: {
        id: userId,
        jobOpportunityId: { eq: jobOpportunityId },
      },
    });
    return data.getUser;
  };

  useEffect(() => {
    (async () => {
      try {
        let job = location?.state?.job ?? null;
        let match = location?.state?.match ?? null;

        document.title = `Match for ${id} - ${auth.user.username} `;

        if (!job) {
          const user = await getUserWithJobMatch(auth.user.id, id);

          const appMatch = user.matches.items.find(
            ({ jobOpportunityId }) => jobOpportunityId === id
          );

          if (appMatch) {
            match = appMatch;

            job = match.jobOpportunity;
          } else {
            //If it is not found, fetch the application for the job type associated with the job opp
            try {
              job = await getFreelancerJobOpp();
            } catch (err) {
              if (!err.message?.includes("Unauthorized")) {
                console.log("Something went wrong get JobOpp", err);
              }
            }

            if (!job) {
              setNotFound(true);
              return;
            } else {
              const applicationJobType = user.applications.items?.find(
                (item) => item.jobTypeId === job.jobTypeId && !item.isNotActive
              );

              applicationId.current = applicationJobType?.id;
            }
          }
        }

        const preferredRate =
          match?.rate?.value ||
          auth.user?.ratePerHour?.value ||
          convertSalaryToRatePerHour(auth.user?.salary?.value) ||
          job?.minRate?.value ||
          0;

        setValue("ratePerHourInput", preferredRate);

        const userStartDateDefault = match?.availableStartDate
          ? dayjs(match.availableStartDate).format("YYYY-MM-DD")
          : job?.startDate;

        setValue("startDate", userStartDateDefault);

        const freelancerPitchDefault =
          match?.freelancerPitch ||
          getCombinedPrefillPitch(
            job?.freelancerPitchPrefill,
            match?.freelancerPitchPrefill
          );

        if (!getValues().freelancerPitch) {
          setValue("freelancerPitch", freelancerPitchDefault);
        }

        const jobReferrals =
          await UtilsLib.Referral.listJobOpportunityReferrals(job?.id, {
            userId: {
              eq: auth.user.id,
            },
            filter: { referralType: { eq: REFERRAL_TYPES.JOB } },
          });

        if (jobReferrals.length > 0) {
          setIsReferral(true);
        }

        delete match?.jobOpportunity;

        setMatch(match);
        setJobOpp(job);
      } catch (err) {
        setError("Something went wrong when initializing component");
        console.log("Something went wrong when initializing component", err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSocialLinks = (key, value) => {
    const newValue = (auth.user?.socialLinks || []).filter(
      (el) => el.type !== key
    );
    if (value.length > 0) newValue.push({ type: key, value });
    saveAttributes({ socialLinks: newValue }, true);
  };

  const isPhoneValid = (phone) => phone?.match(/\d/g)?.length >= 7;

  const updatePhone = (key, value) => {
    if (!isPhoneValid(value)) return;
    saveAttributes({ phone: { ...auth.user.phone, [key]: value } }, true);
  };

  const handleBlur = ({ target }) => {
    const key = target.id;
    const value = target.value !== "" ? target.value.trim() : target.value;
    if (
      getFieldState(key).error ||
      key === "" ||
      key === "freelancerPitch" ||
      key === "startDate" ||
      key === "ratePerHourInput" ||
      key === "currencyInputValue" ||
      key === "react-select-2-input" // currency select imput do nothing on blur
    ) {
      return;
    }

    if (key === SOCIALLINKS.LINKEDIN.key) {
      return updateSocialLinks(key, value.trim());
    }

    if (key === "phone") {
      return updatePhone("number", value);
    }
    if (key === FIELDS.WHATSAPP_ALLOWED) {
      return saveAttributes(
        { phone: { ...auth.user.phone, [key]: target.checked } },
        true
      );
    }
    saveAttributes({ [key]: value }, true);
  };

  const callMatchMutation = async (params, isUpdate) => {
    setIsUpdatingRecord(true);

    const mutation = isUpdate ? updateMatch : createMatch;

    try {
      await API.graphql(graphqlOperation(mutation, params)).then((_data) => {
        if (isUpdate) {
          setMatch((prev) => {
            return { ...prev, status: params.input.status };
          });
        } else {
          setMatch(_data?.data?.createMatch);
        }
      });
    } catch (error) {
      console.error("create match error: ", error);
    } finally {
      setErrors({});
      setIsUpdatingRecord(false);
    }
  };

  const applyToMatchedJob = async (status) => {
    setIsUpdatingRecord(true);

    const basePrefillPitch = getCombinedPrefillPitch(
      jobOpp?.freelancerPitchPrefill,
      match?.freelancerPitchPrefill
    );

    const { freelancerPitch, startDate } = getValues();

    if (
      (jobOpp?.freelancerPitchPrefill || match?.freelancerPitchPrefill) &&
      freelancerPitch === basePrefillPitch
    ) {
      setErrors((prev) => ({
        ...prev,
        freelancerPitch: { message: "Please answer the requested questions" },
      }));
      setIsUpdatingRecord(false);
      return;
    }

    const { ratePerHourInput, usersRange } = getValues();

    const ratePerHour = { value: ratePerHourInput, currency: "USD" };

    if (usersRange) {
      await saveAttributes({
        ratePerHour: { value: ratePerHourInput, currency: "USD" },
      });
    }

    const matchApplicationId = match?.applicationId;

    const params = {
      input: {
        applicationId:
          matchApplicationId || applicationId.current || `User#${auth.user.id}`,
        jobOpportunityId: jobOpp.id,
        status,
        availableStartDate: dayjs(startDate),
        freelancerPitch,
        rate: ratePerHour,
      },
    };

    await callMatchMutation(params, !!matchApplicationId);
  };

  const handleApplyButton = () => {
    const errorsKeys = Object.keys(formErrors);

    if (errorsKeys[0]) {
      setFocus(errorsKeys[0]);

      return;
    }

    applyToMatchedJob(
      isMatched()
        ? JOB_APPLICATION_MATCH_STATUS.APPLIED
        : JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK
    );
  };

  const handleInterested = async () => {
    const matchApplicationId = match?.applicationId;
    const params = {
      input: {
        applicationId:
          matchApplicationId || applicationId.current || `User#${auth.user.id}`,
        jobOpportunityId: jobOpp.id,
        status: JOB_APPLICATION_MATCH_STATUS.INTERESTED,
      },
    };
    callMatchMutation(params, !!matchApplicationId);
  };

  const dispatchUserUpdated = (user) => {
    dispatch({
      type: GLOBAL_AUTH_ACTION_TYPES.USER_UPDATED,
      user,
    });
  };

  const updateUserProfile = async (data) => {
    const res = await API.graphql(
      graphqlOperation(updateUser, { input: data })
    );

    if (res.data.updateUser) {
      // Should result in setUser() in useEffect() being invoked too
      const data = { ...auth.user, ...res.data.updateUser };
      dispatchUserUpdated(data);
    } else {
      throw new Error(
        `Update operation on user failed - no data returned. Update data: ${JSON.stringify(
          data,
          null,
          2
        )}`
      );
    }
  };

  const saveAttributes = async (attributes) => {
    setIsUpdatingRecord(true);
    const attributesToUpdate = {
      id: auth.user.id,
      ...attributes,
    };
    try {
      await updateUserProfile(attributesToUpdate);
    } catch (error) {
      console.log("Error when updating profile info", error);
    }
    setIsUpdatingRecord(false);
  };

  if (notFound) {
    return <Redirect to="/NotFound" />;
  }

  const renderDate = (date) => {
    if (!date) {
      return;
    }
    const startDate = Number(date.split("-").join(""));
    const nowDate = Number(
      dayjs(Date.now()).format("YYYY MM DD").split(" ").join("")
    );

    if (startDate - nowDate > 0) {
      return dayjs(date).format("MM DD YYYY").split(" ").join("/");
    } else {
      return "Immediately";
    }
  };

  const selectUserSkillExperience = (exp) =>
    setSkillSelected({ ...skillSelected, experience: exp });

  const handleOnCancel = () => {
    setSkillSelected(undefined);
  };

  const handleOnSubmit = () => {
    const userSkillsUpdated = auth.user.skills ? [...auth.user.skills] : [];

    const index = userSkillsUpdated.findIndex(
      (s) => s.name === skillSelected.name
    );
    if (index === -1) userSkillsUpdated.push(skillSelected);
    else userSkillsUpdated[index] = skillSelected;

    setUserSkills(userSkillsUpdated);
    setSkillSelected(undefined);
    const skillsToUpdate = userSkillsUpdated.map((skill) => {
      delete skill.hasJobSkill;
      return skill;
    });
    saveAttributes({ skills: skillsToUpdate }, true);
  };

  const calculateRows = () => {
    const { freelancerPitch } = getValues();
    const rows = freelancerPitch?.split("\n").length;
    return rows > 1 ? rows + 1 : 4;
  };

  const handleOnClickCopyLink = (isPrivate) => {
    if (isCopied) return;
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
    const shareType = isPrivate ? "j" : "p";
    navigator.clipboard.writeText(
      `${window.location.origin}/#/${shareType}/${encodeURIComponent(
        id
      )}/${encodeURIComponent(auth.user.referralCode)}/cp`
    );
  };

  const isJobPrivileged = () => {
    return isProfessionalCommunity || isReferral;
  };

  const hasShownInterest = () => {
    return (
      match?.status === JOB_APPLICATION_MATCH_STATUS.INTERESTED ||
      match?.status === JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK
    );
  };

  const isMatched = () => {
    return match?.status === JOB_APPLICATION_MATCH_STATUS.MATCHED;
  };

  const isAccepted = () => {
    return match?.status === JOB_APPLICATION_MATCH_STATUS.ACCEPTED;
  };

  const isApplied = () => {
    return match?.status === JOB_APPLICATION_MATCH_STATUS.APPLIED;
  };

  const isRejected = () => {
    return (
      match?.status === JOB_APPLICATION_MATCH_STATUS.PASSEDON ||
      match?.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER ||
      match?.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER ||
      match?.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER
    );
  };

  const isPublicJob = () => {
    return jobOpp?.visibilityLevel === JOB_OPPORTUNITY_VISIBILITY_LEVELS.PUBLIC;
  };

  const allowFeedBackNotes = isJobPrivileged() && !isReferral;

  const allowSharing = (isJobPrivileged() && !isReferral) || isPublicJob();

  const isFullApplication =
    !isRejected() &&
    (isJobPrivileged() || isMatched() || isApplied() || isAccepted());

  //this previously had logic to hide the full description if you weren't professional community
  //or matched, but as of #1877 it's now visible to everyone
  const showFullJobDescription = true;

  const promptLinkCalendar =
    shouldLinkCalendar &&
    SCHEDULER_ENABLED &&
    !auth.user.nylasAccountId &&
    !auth.user.slugScheduler &&
    (isMatched() || (!hasShownInterest() && !isApplied() && !isAccepted()));

  const showMatchAction =
    (!isFullApplication || (isFullApplication && !promptLinkCalendar)) &&
    !isRejected() &&
    !hasShownInterest() &&
    !isApplied() &&
    !isAccepted();

  const showNotInterestedAction = isMatched();

  const isReadOnly =
    isApplied() || isRejected() || hasShownInterest() || isAccepted();

  const renderMatchInfoCard = (matchStatus) => (
    <>
      {matchStatus === JOB_APPLICATION_MATCH_STATUS.MOREINFO && (
        <InformationCard>
          {`Thank you for applying to the ${jobOpp.title} job. Your profile is in the process of being reviewed and we will be in touch soon.`}
        </InformationCard>
      )}
      {isAccepted() && (
        <InformationCard>
          {`Congratulations, ${auth.user.given_name}! Your hard work has paid off and you have been selected for this position. If they have not already, one of our Success Managers will be in contact with you for onboarding and next steps.`}
        </InformationCard>
      )}
      {isRejected() && (
        <InformationCard>{getRejectedMessage(matchStatus)}</InformationCard>
      )}
      {(isApplied() || hasShownInterest()) && (
        <InformationCard>
          Thank you for your interest, we'll be in touch
          <br />
          In the meantime, click{" "}
          <Link className="transition" to="/profile/wizard/0">
            here
          </Link>{" "}
          to make your profile even better!
        </InformationCard>
      )}
    </>
  );

  return (
    <div>
      <Header />
      {error && <div>something went wrong, please try again</div>}
      {notInterested && (
        <Modal onClose={() => setNotInterested(false)}>
          <NotInterestedInJob
            onClose={() => setNotInterested(false)}
            match={match}
            job={jobOpp}
          />
        </Modal>
      )}
      {jobOpp ? (
        <>
          <div className="static w-full px-2">
            <div className="flex items-center max-w-5xl mx-auto">
              <svg
                className="h-auto pt-8"
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="6"
                viewBox="0 0 33 6"
                fill="none"
              >
                <path
                  d="M0.75 2.90625L5.75 5.793V0.0194986L0.75 2.90625ZM32.75 2.40625L5.25 2.40625V3.40625L32.75 3.40625V2.40625Z"
                  fill="#202021"
                />
              </svg>
              <Link
                className="font-rubik font-semibold pl-[16px] pt-8"
                to="/jobs/matches"
              >
                Go Back
              </Link>
            </div>
            <div className="flex justify-start md:justify-between items-center md:gap-6 max-w-2xl lg:max-w-5xl mx-auto py-12 pr-4">
              <h1 className="text-4xl font-nexa font-bold mb-2">
                {jobOpp.title}
              </h1>
            </div>
            {allowSharing && (
              <div className="absolute justify-center p-10 flex flex-col top-16 right-0">
                <div
                  className="flex items-center relative w-[100px]"
                  onClick={() =>
                    handleOnClickCopyLink(isJobPrivileged() && !isReferral)
                  }
                >
                  {isCopied ? (
                    <div className="flex relative w-auto h-10">
                      <ShareIconCopied
                        title="Link Copied!"
                        className="absolute mb-2 ml-4 h-10 w-10 z-10"
                      ></ShareIconCopied>
                    </div>
                  ) : (
                    <div className="flex relative w-auto h-10">
                      <ShareIcon
                        className="absolute mb-2 ml-4 h-10 w-10 cursor-pointer hover:opacity-0 z-10"
                        title="Click to Copy Link"
                      />
                      <ShareIconHover
                        className="absolute mb-2 ml-4 h-10 w-10 cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-500 ease-in-out z-20"
                        title="Click to Copy Link"
                      />
                    </div>
                  )}
                </div>
                {allowFeedBackNotes && (
                  <div>
                    <NotesWrapper
                      jobOpportunityId={id}
                      noteType={NOTE_TYPES.JOBFEEDBACK}
                      className={"!text-gray-900 w-[100px]"}
                      title={"We Value Your Voice!"}
                      subTitle={"Share Your Feedback About This Job"}
                      Icon={() => {
                        return (
                          <div className="flex relative w-auto h-10">
                            <FeedBackIcon
                              className="absolute inset-0 w-14 h-12 cursor-pointer object-cover hover:opacity-0 z-10"
                              src="images/Feedback_icon.svg"
                              title="Give Us Feedback"
                            />
                            <FeedbackHoverIcon
                              className="absolute inset-0 w-14 h-12 cursor-pointer object-cover opacity-0 hover:opacity-100 transition-opacity duration-500 ease-in-out z-20"
                              src="images/Feedback_icon_after.svg"
                              title="Give Us Feedback"
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <hr className="border border-black" />
          <div className="max-w-5xl mx-auto grid grid-cols md:grid-cols-2">
            {showFullJobDescription ? (
              <>
                <div className="border-r-2 border-black">
                  <div className="px-8 py-12 w-full">
                    <div className="text-electricBlue font-nexa text-xl mb-4">
                      Job Details
                    </div>
                    <div className="text-electricBlue-800 font-rubik-regular font-bold italic leading-snug">
                      <p>
                        {getJobDuration(
                          jobOpp.jobLength,
                          jobOpp.jobLengthInWeeks
                        )}
                      </p>
                      <p>
                        Start Date:{" "}
                        {jobOpp?.startDate && renderDate(jobOpp.startDate)}
                      </p>
                      <p>Timezone: {jobOpp.timezone?.label}</p>
                      <p>
                        Timezone overlap:{" "}
                        {jobOpp.timeOverlap > 1 && jobOpp.timeOverlap < 8
                          ? `${jobOpp.timeOverlap} hours`
                          : jobOpp.timeOverlap >= 8
                          ? "All hours"
                          : "No Restriction"}
                      </p>
                    </div>
                  </div>
                  {allowSharing && (
                    <div className="relative justify-center items-center">
                      <JobsBackground className="flex absolute w-full h-auto justify-center items-center z-10" />
                      <div className="flex relative items-center text-electricBlue-800 font-rubik-regular font-bold italic leading-snug">
                        <p className="pr-16 py-12 w-3/4 text-left text-lg">
                          Don't forget - share this job and potentially get a
                          $500 dollar referral bonus for yourself and a $250
                          bonus for your friend (if hired)!
                        </p>
                        <div
                          onClick={() =>
                            handleOnClickCopyLink(
                              isJobPrivileged() && !isReferral
                            )
                          }
                          className="flex flex-col justify-center items-center relative"
                        >
                          {isCopied ? (
                            <div className="flex relative w-auto h-10">
                              <ShareIconCopied
                                title="Link Copied!"
                                className="absolute mb-2 ml-4 h-10 w-10 z-10"
                              ></ShareIconCopied>
                            </div>
                          ) : (
                            <div className="flex relative w-auto h-10">
                              <ShareIcon
                                className="absolute mb-2 ml-4 h-10 w-10 cursor-pointer hover:opacity-0 z-10"
                                title="Click to Copy Link"
                              />
                              <ShareIconHover
                                className="absolute mb-2 ml-4 h-10 w-10 cursor-pointer opacity-0 hover:opacity-100 transition-opacity duration-500 ease-in-out z-20"
                                title="Click to Copy Link"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {allowFeedBackNotes && (
                        <div className="flex  relative z-20 items-center text-electricBlue-800 font-rubik-regular font-bold italic leading-snug">
                          <p className="pr-16 w-3/4 text-left text-lg">
                            We want your feedback on this job posting! See some
                            confusing text? Are we missing anything? Did we get
                            something wrong? Let us know!
                          </p>
                          <NotesWrapper
                            jobOpportunityId={id}
                            noteType={NOTE_TYPES.JOBFEEDBACK}
                            className={"!text-gray-900 w-[100px]"}
                            title={"We Value Your Voice!"}
                            subTitle={"Share Your Feedback About This Job"}
                            Icon={() => {
                              return (
                                <div className="flex relative w-auto h-10">
                                  <FeedBackIcon
                                    className="absolute inset-0 w-14 h-12 cursor-pointer object-cover hover:opacity-0 z-10"
                                    src="images/Feedback_icon.svg"
                                    title="Give Us Feedback"
                                  />
                                  <FeedbackHoverIcon
                                    className="absolute inset-0 w-14 h-12 cursor-pointer object-cover opacity-0 hover:opacity-100 transition-opacity duration-500 ease-in-out z-20"
                                    src="images/Feedback_icon_after.svg"
                                    title="Give Us Feedback"
                                  />
                                </div>
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="p-12 flex flex-col gap-8">
                  <div>
                    <label className="text-lg font-semibold">Skills</label>
                    <h4 className="font-rubik-regular text-base italic font-light">
                      Required:{" "}
                      <span className="text-electricBlue ">
                        {jobOpp.skills?.map((s) => s.name).join(", ")}
                      </span>
                    </h4>

                    {jobOpp.optionalSkills?.length > 0 && (
                      <h4 className="font-rubik-regular text-base  italic font-light">
                        Others:{" "}
                        <span className="text-electricBlue ">
                          {jobOpp.optionalSkills?.map((s) => s.name).join(", ")}
                        </span>
                      </h4>
                    )}
                  </div>
                  <p
                    className="prose"
                    dangerouslySetInnerHTML={{
                      __html: getPurifiedParsedMarkDown(jobOpp.overview),
                    }}
                  />
                  <h3 className="-mb-6 text-lg">Responsibilities</h3>
                  <p
                    className="prose"
                    dangerouslySetInnerHTML={{
                      __html: getPurifiedParsedMarkDown(
                        jobOpp.responsibilities
                      ),
                    }}
                  />
                  <h3 className="-mb-6 text-lg">Requirements</h3>
                  <p
                    className="prose"
                    dangerouslySetInnerHTML={{
                      __html: getPurifiedParsedMarkDown(jobOpp.requirements),
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="px-8 py-12 w-full">
                <div className="text-electricBlue font-nexa text-xl mb-4">
                  Job Details
                </div>
                <div className="text-electricBlue-800 font-rubik-regular font-bold italic leading-snug">
                  <p
                    className="prose"
                    dangerouslySetInnerHTML={{
                      __html: getPurifiedParsedMarkDown(
                        jobOpp.shortDescription ?? jobOpp.overview
                      ),
                    }}
                  />
                </div>
              </div>
            )}
            {isFullApplication && promptLinkCalendar ? (
              <div className="col-span-2" ref={linkCalendarRef}>
                <hr className="border-black border w-[100vw] absolute left-0" />
                <div className="flex flex-col items-center mt-8 mb-2">
                  <div>
                    <h3 className="text-lg self-start pt-5">
                      Before you complete your application please link your
                      personal calendar to make interview scheduling a breeze!
                    </h3>
                  </div>
                  <div className="mt-4 pt-5">
                    <ScheduleLinkButton
                      googleOnly={isUserNylasGoogleOnly()}
                      redirectURL={window.location.href}
                      errorMessage={
                        params.get("error") &&
                        "Something went wrong, please try again later."
                      }
                      className={`${params.get("error") ? "!mb-2" : ""}`}
                      nylasVersion={nylasVersion}
                    />
                  </div>
                  <div className="mb-2">
                    <div className="flex gap-x-2 items-center">
                      <button
                        className="w-[17px] h-[17px] border-2 rounded-xs border-brandSecondary"
                        onClick={(e) => setShouldLinkCalendar((prev) => !prev)}
                      />
                      <div className="">not now</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div ref={applicationRef} className="col-span-2">
                <hr className="border-black border w-[100vw] absolute left-0" />
                {isFullApplication && (
                  <div className="mt-8 mb-8">
                    {SCHEDULER_ENABLED && auth.user.slugScheduler ? (
                      <div className="flex flex-col items-center mt-8 mb-2">
                        <ScheduleEditButton
                          googleOnly={isUserNylasGoogleOnly()}
                        />
                      </div>
                    ) : (
                      shouldLinkCalendar &&
                      !isReadOnly && (
                        <p className="font-nexa font-bold mb-2">
                          Your scheduling page will available shortly.
                        </p>
                      )
                    )}
                    <h3 className="text-lg font-rubik-regular mb-2">
                      Application
                    </h3>

                    <FormProvider {...methods}>
                      <form onBlur={handleBlur}>
                        <div className="text-base font-rubik-bold mt-8 mb-2">
                          Available start date
                        </div>
                        <p className="text-base font-rubik-regular mb-9">
                          If you are hired for this job, what is the earliest
                          date you can begin working?
                        </p>

                        <Input
                          type="date"
                          label="Start date"
                          id="startDate"
                          isReadOnly={isReadOnly}
                          focusDatePicker
                          classOverrides="w-3/4"
                        />

                        <Rate
                          id="ratePerHourInput"
                          schemaShapeRef={schemaShapeRef}
                          currencyInputKeyId="currencyInputValue"
                          allowConversion={conversionIsAllowed}
                          defaultCurrency={
                            locationINRConversionEnabled &&
                            CURRENCY_CONFIG.exchangeFactor
                              ? getINRDefaultCurrency(CURRENCY_CONFIG)
                              : {}
                          }
                          initialCurrencies={initialCurrencies}
                          useApi={
                            !CURRENCY_CONFIG.exchangeFactor &&
                            CURRENCY_CONFIG.url
                          }
                          isReadOnly={isReadOnly}
                        />

                        {jobOppSkills?.length > 0 && (
                          <>
                            <div className="text-base font-rubik-bold mt-24 mb-4">
                              Skills required for this job
                            </div>
                            {jobOpp.skills?.length > 0 && (
                              <>
                                <div className="text-base font-rubik mb-8">
                                  You must indicate years of experience for each{" "}
                                  <strong>required</strong> skill that you have
                                </div>
                                <ApplicationSkills
                                  skills={jobOppSkills}
                                  setSkillSelected={setSkillSelected}
                                />
                              </>
                            )}
                            {jobOpp.optionalSkills?.length > 0 && (
                              <>
                                <div className="text-base font-rubik mb-8">
                                  {jobOpp.skills?.length > 0
                                    ? "And "
                                    : "You must indicate years of experience "}
                                  for each <strong>optional</strong> skill that
                                  you have
                                </div>
                                <ApplicationSkills
                                  skills={jobOppOptSkills}
                                  setSkillSelected={setSkillSelected}
                                  deleteSkill={deleteSkill}
                                />
                              </>
                            )}
                          </>
                        )}
                        {!isUpdatingRecord &&
                          jobOppSkills
                            .filter((jobSkill) =>
                              userSkills.some(
                                (userSkill) => userSkill.name === jobSkill.name
                              )
                            )
                            .some(
                              (skill) => skill.experience === undefined
                            ) && (
                            <Error
                              className="mt-4"
                              message="Please select your experience on every skill"
                            />
                          )}
                        <div className="text-base font-rubik-bold mt-16 mb-4">
                          Your other skills
                        </div>
                        <div className="text-base font-rubik mb-8">
                          Add any additional skills that may improve your
                          application
                        </div>
                        <Skills
                          skills={userSkills.filter((skill) =>
                            [
                              ...(jobOpp.skills || []),
                              ...(jobOpp.optionalSkills || []),
                            ]?.every((jobSkill) => jobSkill.name !== skill.name)
                          )}
                          setSkills={setUserSkills}
                          user={auth?.user}
                          showTitle={false}
                          save={saveAttributes}
                        />

                        <div className="w-3/4 mt-24">
                          <Phone
                            phone={auth?.user?.phone}
                            handleChange={handleBlur}
                          />
                        </div>
                        {!isPhoneValid(auth.user.phone?.number) && (
                          <Error
                            className="mt-4"
                            message="Please enter your phone number"
                          />
                        )}

                        <div className="w-3/4 mt-16">
                          <DefaultInput
                            id={SOCIAL_LINK_TYPES.LINKEDIN}
                            label={SOCIALLINKS.LINKEDIN.label}
                            defaultValue={
                              auth.user.socialLinks?.find(
                                (l) => l.type === SOCIAL_LINK_TYPES.LINKEDIN
                              )?.value
                            }
                            subtext="Username"
                          />
                        </div>
                        {!auth.user.socialLinks?.find(
                          (el) => el.type === "LINKEDIN" && el.value
                        ) && (
                          <Error message="Please enter your linkedin profile" />
                        )}
                        <div className="w-3/4 mt-8 mb-8">
                          {jobOpp?.freelancerPitchPrefill ||
                          match?.freelancerPitchPrefill ? (
                            <div className="text-base font-rubik-bold mt-8 mb-4">
                              Just a few more questions
                            </div>
                          ) : (
                            <>
                              <div className="text-base font-rubik-bold mt-8 mb-2">
                                Message to customer
                              </div>
                              <p className="text-base font-rubik-regular mb-4">
                                Is there anything you would like to tell the
                                customer?
                              </p>
                            </>
                          )}

                          <Textarea
                            id="freelancerPitch"
                            rows={calculateRows()}
                            onChange={(e) => {
                              if (errors.freelancerPitch?.message) {
                                setErrors((prev) => ({
                                  ...prev,
                                  freelancerPitch: { message: "" },
                                }));
                              }
                              setValue("freelancerPitch", e.target.value);
                              e.target.rows = calculateRows();
                            }}
                            isReadOnly={isReadOnly}
                          />
                          <p className="text-red-500">
                            {errors.freelancerPitch?.message}
                          </p>
                        </div>
                        {!isReadOnly && (
                          <div className="flex relative items-center">
                            <Checkbox
                              id="usersRange"
                              label="Contact Me via WhatsApp. The team will reach out to you on WhatsApp for new job postings and status updateson existing job applications"
                            />
                            <div className="ml-4">
                              <div className="b3">
                                Check the box to update your default rate on
                                your profile to match this job rate. Torc uses
                                your default rate to match you with future
                                opportunities.
                              </div>
                            </div>
                          </div>
                        )}
                      </form>
                    </FormProvider>
                  </div>
                )}
                {renderMatchInfoCard(match?.status)}

                <div className="flex w-full justify-between mb-8 mt-8 px-2">
                  <Link className="transition" to="/jobs/matches">
                    <PrimaryBtn label="Go back" />
                  </Link>

                  {showMatchAction && isFullApplication ? (
                    <PrimaryBtn
                      label="APPLY NOW"
                      loading={isUpdatingRecord}
                      disabled={
                        isUpdatingRecord ||
                        (isJobPrivileged() &&
                          (!getValues().ratePerHourInput ||
                            !getValues().startDate ||
                            !isPhoneValid(auth.user.phone?.number) ||
                            !auth.user.socialLinks?.find(
                              (el) => el.type === "LINKEDIN" && el.value
                            ) ||
                            ![...jobOppSkills, ...jobOppOptSkills]
                              ?.filter((jobSkill) => jobSkill.hasJobSkill)
                              .every((jobSkill) => jobSkill.experience)))
                      }
                      onClick={() => {
                        handleApplyButton();
                      }}
                    />
                  ) : (
                    showMatchAction && (
                      <PrimaryBtn
                        label="I'M INTERESTED"
                        loading={isUpdatingRecord}
                        disabled={isUpdatingRecord}
                        onClick={() => handleInterested()}
                      />
                    )
                  )}
                </div>
                <div>
                  {showNotInterestedAction && (
                    <div className="flex justify-end mb-4">
                      <p
                        className="text-blue-600 hover:underline cursor-pointer"
                        onClick={() => setNotInterested(true)}
                      >
                        Not interested?
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className="w-full flex items-center justify-center"
          style={{
            height: "65vh",
          }}
        >
          <span className="loader" />
        </div>
      )}
      <Footer />
      {skillSelected && (
        <SkillsModal
          skill={skillSelected}
          handleSetExperience={selectUserSkillExperience}
          handleOnCancel={handleOnCancel}
          handleOnSubmit={handleOnSubmit}
        />
      )}
    </div>
  );
};

export default MatchedJob;
