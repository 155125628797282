const TORC = "https://www.torc.dev";

const URLS = {
  HOME: TORC,
  ACCOMMODATIONS: `${TORC}/${process.env.REACT_APP_ACCOMMODATIONS_PATH}`,
  TERMS: `${TORC}/terms`,
  CORE_PRINCIPLES: `${TORC}/core-principles`,
  PRIVACY: `${TORC}/privacy-policy`,
  HIRE_DEVELOPERS: `${TORC}/hire-developers/`,
  APPLY_JOBS: `${TORC}/apply-for-jobs/`,
  TORC_PRODUCTIVITY: `${TORC}/talent/productivity/`,
  TORC_REFERRAL: `${TORC}/talent/referrals/`,
  TORC_CORE_PRINCIPLES: `${TORC}/core-principles/`,
  ABOUT_US: `${TORC}/about-us`,
  BLOG: `${TORC}/blog`,
  CONTACT_US: `${TORC}/contact/`,
  NEWSROOM: `${TORC}/newsroom`,
  CAREER: `${TORC}/career`,
  TALENT_AGREEMENT: `${TORC}/talent-agreement`,
  TWITCH: "https://www.twitch.tv/opentorc",
  LINKEDIN: "https://www.linkedin.com/company/opentorc",
  DISCORD: "https://www.torc.dev/discord",
  TWITTER: "https://www.x.com/torcdotdev",
  GITHUB: "https://github.com/opentorc",
  ZAPIER: "https://hooks.zapier.com/hooks/catch/11263188/bv2b0ar/",
  SEARCH_TOOL: process.env.REACT_APP_SEARCH_TOOL_DOMAIN,
};

export default URLS;
